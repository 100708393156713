<template>
  <div>
    <header>
      <div>
        <h1>Contact</h1>
      </div>
    </header>
    <section class="container-md my-40">
      <div class="item">
        <div>
          <v-img contain class="img" src="../assets/images/scads_color.webp" />
        </div>
        <div>
          <h3>Scalable Data Systems Lab (SCADS)</h3>
          <p>Floor 2, M4 (IST) Building</p>
          <p>School of Information Science and Technology</p>
          <p>Vidyasirimedhi Institute of Science and Technology</p>
          <p>
            Wangchan Valley, 555 Moo 1, Payupnai, Wangchan, Rayong, 21210
            Thailand
          </p>
          <p>Email: bundit.b_s18@vistec.ac.th</p>
        </div>
      </div>
      <div class="my-40">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3642.9681159586794!2d101.4405682584997!3d13.000068968002957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310323963a63f571%3A0x87e3c90d9f7064db!2sSchool%20of%20Information%20Science%20and%20Technology%2C%20VISTEC!5e0!3m2!1sen!2sth!4v1581589186050!5m2!1sen!2sth"
          width="100%"
          height="500px"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
        ></iframe>
      </div>
    </section>
  </div>
</template>

<script>
// import GoogleMap from "@/components/GoogleMap.vue";

export default {
  name: "contact",
  components: {
    // GoogleMap
  },
  methods: {},
  props: {
    setActive: Function
  },
  created() {
    this.setActive(this.$route.name.toLowerCase());
  }
};
</script>

<style scoped>
header::before {
  background-image: url("../assets/images/DJI_0120.webp");
}

.item {
  display: grid;
  grid-template-columns: 30% 65%;
  color: #186aa5;
}
.item .img {
  width: 80%;
}
.item > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item h3 {
  margin-bottom: 20px;
}
.item p {
  margin: 0;
}
.item p:last-child {
  margin-top: 20px;
}
@media screen and (max-width: 575px) {
  .item .img {
    width: 50vw;
  }
  .item {
    grid-template-columns: 1fr;
  }
  .item > div {
    margin: 20px auto;
    text-align: center;
  }
}
</style>
